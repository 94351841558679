var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CToaster',{attrs:{"autohide":2000}},[[(_vm.showSuccessToast)?_c('div',[_c('CToast',{attrs:{"show":_vm.showSuccessToast,"color":"success"},on:{"update:show":function($event){_vm.showSuccessToast=$event}}},[_vm._v(" "+_vm._s(_vm.successToastMessage)+" ")])],1):_vm._e()],[(_vm.showErrorToast)?_c('div',[_c('CToast',{attrs:{"show":_vm.showErrorToast,"color":"danger"},on:{"update:show":function($event){_vm.showErrorToast=$event}}},[_vm._v(" "+_vm._s(_vm.errorToastMessage)+" ")])],1):_vm._e()]],2),_c('CCol',{staticStyle:{"max-width":"90rem"},attrs:{"col":"12","xl":"10"}},[_c('CCard',[_c('CCardHeader',[_c('CButton',{staticClass:"float-left",attrs:{"disabled":"","variant":"ghost","color":"dark"}},[_vm._v("Reports")]),_c('CButton',{staticClass:"ml-5 float-right",attrs:{"color":"dark","disabled":true},on:{"click":function($event){return _vm.openHelp(true)}}},[_c('span',{staticClass:"material-icons md-18"},[_vm._v("question_mark")])]),_c('CButton',{staticClass:"ml-5 float-right col-sm-4 col-xl-2",attrs:{"variant":"outline","color":"success"},on:{"click":_vm.createReport}},[_vm._v("Create Report")])],1),_c('CCardBody',[_c('CDataTable',{attrs:{"sorter":"","column-filter":"","items-per-page-select":"","hover":"","striped":"","sorter-value":{ column: 'report_title', asc: 'true' },"items":_vm.reportsTableItems,"fields":_vm.fields,"items-per-page":20,"active-page":_vm.activePage,"pagination":{ doubleArrows: true, align: 'center'},"loading":_vm.loading},scopedSlots:_vm._u([{key:"report_customer_name",fn:function(ref){
var item = ref.item;
return _c('td',{},[(item.report_customer_name == 'undefined')?[_c('CBadge',{attrs:{"color":"danger"}},[_vm._v(" Error ")])]:[_vm._v(" "+_vm._s(item.report_customer_name)+" ")]],2)}},{key:"report_workload_name",fn:function(ref){
var item = ref.item;
return _c('td',{},[(item.report_workload_name == 'undefined')?[_c('CBadge',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({ content: 'Workload could not be found. Please contact Support team.' }),expression:"{ content: 'Workload could not be found. Please contact Support team.' }"}],attrs:{"color":"danger"}},[_vm._v(" Error ")])]:[_vm._v(" "+_vm._s(item.report_workload_name)+" ")]],2)}},{key:"report_last_url",fn:function(ref){
var item = ref.item;
return _c('td',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({ content: 'Open the Report in a new tab.' }),expression:"{ content: 'Open the Report in a new tab.' }"}]},[_c('CButton',{staticStyle:{"display":"inline-grid"},attrs:{"id":item.report_last_url,"href":item.report_last_url,"disabled":item.report_last_url.length < 1,"target":"_blank","color":"info"}},[_c('span',{staticClass:"material-icons md-18"},[_vm._v("link")])])],1)}},{key:"report_edit",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('CButton',{staticStyle:{"display":"inline-grid"},attrs:{"id":item.report_id,"disabled":item.is_broken,"block":"","color":"dark"},on:{"click":function($event){return _vm.openReport(item.report_id)}}},[_c('span',{staticClass:"material-icons md-18"},[_vm._v("edit")])])],1)}},{key:"report_delete",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('CButton',{staticStyle:{"display":"inline-grid"},attrs:{"id":item.report_id,"block":"","color":"danger"},on:{"click":function($event){return _vm.deleteReport(item)}}},[_c('span',{staticClass:"material-icons md-18"},[_vm._v("delete")])])],1)}}])})],1)],1)],1),_c('CModal',{attrs:{"title":"Delete Report","color":"danger","show":_vm.deleteModal},on:{"update:show":function($event){_vm.deleteModal=$event}}},[_c('p',[_vm._v("Do you really want to delete the Report "),_c('strong',[_vm._v(" "+_vm._s(_vm.deleteReportItem.report_name))]),_vm._v("?")]),_c('p',[_vm._v("This "),_c('strong',[_vm._v("can't")]),_vm._v(" be undone!")]),_c('div',{staticClass:"w-100",attrs:{"slot":"footer"},slot:"footer"},[_c('CButton',{staticClass:"col-sm-2 ml-1 mr-1 float-right",attrs:{"color":"danger"},on:{"click":function($event){return _vm.deleteReportApi(_vm.deleteReportItem.report_id);}}},[_vm._v("Delete")]),_c('CButton',{staticClass:"ml-1 mr-1 col-sm-2 float-right",attrs:{"color":"light"},on:{"click":function($event){_vm.deleteModal = false}}},[_vm._v("Cancle")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }